export default class Result<Ts, Te> {
  public success: boolean = false;
  public value: Ts | null = null;
  public error: Te | null = null;

  public succeed(value: Ts) {
    this.success = true;
    this.value = value;
    return this;
  }

  public fail(error: Te) {
    this.success = false;
    this.error = error;
    return this;
  }

  public ifSuccess(handler: (value: Ts) => void) {
    if (this.success) handler(this.value as Ts);
  }

  public ifError(handler: (value: Te) => void) {
    if (!this.success) handler(this.error as Te);
  }
}
