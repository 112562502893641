import styled from "@emotion/styled";

export interface IInputProps {
  borderBottomWidth?: string;
  borderBottomColor?: string;
  paddingBottom?: string;
}
const TextInput = styled.input<IInputProps>`
  font-size: inherit;
  border: none;
  border-bottom: ${({ borderBottomWidth = "1px" }) => borderBottomWidth} solid
    ${({ borderBottomColor = "transparent" }) => borderBottomColor};
  padding: 0;
  padding-bottom: ${({ paddingBottom = "1px" }) => paddingBottom};

  &::placeholder {
    color: #c0c0c0;
  }

  &:focus {
    outline: none;
    border-bottom: ${({ borderBottomWidth = "1px" }) => borderBottomWidth} solid
      #0999e0;
  }
`;

export default TextInput;
