import { observable } from "mobx";
import { persist } from "mobx-persist";
import React from "react";
import { ILoginResponse, IProfileResponse } from "../api/user";

// TODO: since we persist token, need to deal with it expiring or becoming invalid

export class UserStore {
  @persist @observable public token: string = "";
  @persist @observable public userName: string = "";

  public logout() {
    this.token = "";
    this.userName = "";
  }

  public isLoggedIn = () => this.token !== "";

  public setToken(data: ILoginResponse) {
    // TODO: store expiration?
    this.token = data.access_token;
  }

  public setProfile(data: IProfileResponse) {
    this.userName = data.userName;
  }
}

const UserContext = React.createContext(new UserStore());

export default UserContext;
