import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import TextInput, { IInputProps } from "../atoms/TextInput";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const DollarSign = styled.div<{ paddingBottom: string }>`
  margin-right: 0.2rem;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  color: #c0c0c0;
`;

const computeDollarSignBottomPadding = (
  inputPaddingBottom?: string,
  inputBorderBottomWidth?: string
) => {
  if (inputPaddingBottom && inputBorderBottomWidth) {
    return `calc(${inputPaddingBottom} + ${inputBorderBottomWidth})`;
  } else if (inputPaddingBottom) {
    return inputPaddingBottom;
  } else if (inputBorderBottomWidth) {
    return inputBorderBottomWidth;
  } else {
    return "1px";
  }
};

interface IProps extends React.HTMLProps<HTMLInputElement>, IInputProps {}
const AmountInput = forwardRef<HTMLInputElement, IProps>(
  ({ className, paddingBottom, borderBottomWidth, ...props }, ref) => {
    return (
      <Container className={className}>
        <DollarSign
          paddingBottom={computeDollarSignBottomPadding(
            paddingBottom,
            borderBottomWidth
          )}
        >
          $
        </DollarSign>
        <TextInput
          type="text"
          placeholder="Amount"
          paddingBottom={paddingBottom}
          borderBottomWidth={borderBottomWidth}
          ref={ref}
          {...props}
        />
      </Container>
    );
  }
);

export default AmountInput;
