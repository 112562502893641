import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Route, Redirect, RouteProps } from "react-router-dom";
import UserContext from "../../contexts/UserContext";

const AnonymousRoute: React.FC<RouteProps> = observer(
  ({ component, ...props }) => {
    const user = useContext(UserContext);

    if (user.isLoggedIn()) {
      return <Route {...props} render={() => <Redirect to="/" />} />;
    } else {
      return <Route {...props} component={component} />;
    }
  }
);

export default AnonymousRoute;
