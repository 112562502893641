import React, { useContext, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import BankDataContext, {
  IBank,
  IBankAccount,
  ITransaction,
  ICategory
} from "../contexts/BankDataContext";
import {
  getBanks,
  getBankAccounts,
  getTransactions,
  getCategories
} from "../api/banks";
import UserContext from "../contexts/UserContext";
import useAsyncEffect from "../util/useAsyncEffect";
import TransactionListItem, {
  FocusableField,
  FocusFieldRequest
} from "../components/organisms/TransactionListItem";
import Result from "../util/Result";
import styled from "@emotion/styled";
import LoadingSpinner from "../components/atoms/LoadingSpinner";

const Container = styled.div`
  max-width: 50rem;
  margin: 0 auto;
`;

const PageHeading = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

interface IListItemFocus {
  index: number;
  request: FocusFieldRequest;
}

const TransactionsScreen = observer(() => {
  const user = useContext(UserContext);
  const bankData = useContext(BankDataContext);
  const [loading, setLoading] = useState(true);
  const [focusedItem, setFocusedItem] = useState<IListItemFocus | undefined>();

  const onAdvanceListFocus = useCallback(
    (transactionId: string, field: FocusableField) => {
      const index = bankData.transactions.findIndex(
        t => t.id === transactionId
      );
      if (index >= 0) {
        setFocusedItem({
          index: index + 1,
          request: {
            field: field
          }
        });
      }
    },
    [bankData.transactions]
  );

  useAsyncEffect(async () => {
    // This is really unfortunate, but a consequence of TypeScript's
    // insufficient Promise.all type signature.
    const [
      banksResult,
      accountsResult,
      transactionsResult,
      categoriesResult
    ] = await Promise.all<
      Result<IBank[], any>,
      Result<IBankAccount[], any>,
      Result<ITransaction[], any>,
      Result<ICategory[], any>
    >([
      getBanks(user.token),
      getBankAccounts(user.token),
      getTransactions(user.token),
      getCategories(user.token)
    ]);

    banksResult.ifSuccess(b => (bankData.banks = b));
    accountsResult.ifSuccess(a => (bankData.accounts = a));
    transactionsResult.ifSuccess(t => (bankData.transactions = t));
    categoriesResult.ifSuccess(c => (bankData.categories = c));

    setLoading(false);
  }, [user.token]);

  let lastRenderedTxnDate = "";

  return (
    <Container>
      <PageHeading>Transactions</PageHeading>
      {loading && <LoadingSpinner />}
      {!loading &&
        bankData.transactions.map((transaction, i) => {
          const showDate = lastRenderedTxnDate !== transaction.date;
          lastRenderedTxnDate = transaction.date;

          return (
            <TransactionListItem
              showDate={showDate}
              key={transaction.id}
              transaction={transaction}
              focusField={
                focusedItem && focusedItem.index === i
                  ? focusedItem.request
                  : undefined
              }
              advanceListFocus={onAdvanceListFocus}
            />
          );
        })}
    </Container>
  );
});

export default TransactionsScreen;
