import React, { useContext, useState, useEffect, forwardRef } from "react";
import BankDataContext, { ICategory } from "../../contexts/BankDataContext";
import styled from "@emotion/styled";
import useForwardRefSpy from "../../util/useForwardRefSpy";
import TextInput, { IInputProps } from "../atoms/TextInput";
import Hashtag from "../atoms/Hashtag";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TypeaheadWrapper = styled.div`
  flex-grow: 1;
`;

const SuggestionsContainer = styled.div`
  position: relative;
`;

const SuggestionsList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #c0c0c0;
  border-top: none;
`;

interface ISuggestionProps {
  isHighlighted: boolean;
}
const Suggestion = styled.div<ISuggestionProps>`
  color: ${(props: ISuggestionProps) =>
    props.isHighlighted ? "#0999e0" : "inherit"};
  font-weight: ${(props: ISuggestionProps) =>
    props.isHighlighted ? "bold" : "normal"};
  padding: 0.3rem;
`;

function filterCategories(categories: ICategory[], value: string) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  const results =
    inputLength === 0
      ? []
      : categories.filter(
          category =>
            category.name.toLowerCase().slice(0, inputLength) === inputValue
        );

  if (results.length === 1 && results[0].name === value) return [];

  return results;
}

const computeHashtagBottomPadding = (
  inputPaddingBottom?: string,
  inputBorderBottomWidth?: string
) => {
  if (inputPaddingBottom && inputBorderBottomWidth) {
    return `calc(${inputPaddingBottom} + ${inputBorderBottomWidth})`;
  } else if (inputPaddingBottom) {
    return inputPaddingBottom;
  } else if (inputBorderBottomWidth) {
    return inputBorderBottomWidth;
  } else {
    return "1px";
  }
};

export enum CommitAction {
  EnterKey,
  Click,
  Blur
}

interface IPendingCommit {
  action: CommitAction;
  value: string;
}

interface IProps extends IInputProps {
  value: string;
  disabled?: boolean;
  onCommit: (value: string, action: CommitAction) => void;
  className?: string;
}

const CategoryInput = forwardRef<HTMLInputElement, IProps>(
  (
    {
      value: initialValue,
      disabled = false,
      onCommit,
      className,
      paddingBottom,
      borderBottomWidth,
      ...props
    }: IProps,
    outerRef
  ) => {
    const bankData = useContext(BankDataContext);
    const inputRef = useForwardRefSpy<HTMLInputElement>(outerRef);

    const [value, setValue] = useState("");
    useEffect(() => setValue(initialValue), [initialValue]);

    const [suggestions, setSuggestions] = useState<ICategory[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [focusedSuggestion, setFocusedSuggestion] = useState(0);
    useEffect(() => {
      setFocusedSuggestion(0);
      setSuggestions(filterCategories(bankData.categories, value));
    }, [bankData.categories, value]);

    const [pendingCommit, setPendingCommit] = useState<
      IPendingCommit | undefined
    >();
    useEffect(() => {
      if (pendingCommit) {
        onCommit(pendingCommit.value, pendingCommit.action);

        if (pendingCommit.action !== CommitAction.Blur)
          inputRef.current && inputRef.current.blur();

        setPendingCommit(undefined);
      }
    }, [pendingCommit, onCommit, inputRef]);

    return (
      <Container className={className}>
        <Hashtag
          paddingBottom={computeHashtagBottomPadding(
            paddingBottom,
            borderBottomWidth
          )}
        />
        <TypeaheadWrapper>
          <TextInput
            type="text"
            placeholder="AddCategory"
            ref={inputRef}
            value={value}
            disabled={disabled}
            paddingBottom={paddingBottom}
            borderBottomWidth={borderBottomWidth}
            onChange={event => setValue(event.target.value)}
            onFocus={event => {
              event.target.select();
              setShowSuggestions(true);
            }}
            onBlur={event => {
              setPendingCommit({
                action: CommitAction.Blur,
                value: event.target.value
              });
              setShowSuggestions(false);
            }}
            onKeyDown={event => {
              switch (event.key) {
                case "ArrowDown":
                  event.preventDefault();
                  if (focusedSuggestion < suggestions.length - 1)
                    setFocusedSuggestion(focusedSuggestion + 1);
                  break;
                case "ArrowUp":
                  event.preventDefault();
                  if (focusedSuggestion > 0)
                    setFocusedSuggestion(focusedSuggestion - 1);
                  break;
                case "Enter":
                  event.preventDefault();

                  const currentSuggestion = suggestions[focusedSuggestion];
                  if (currentSuggestion) setValue(currentSuggestion.name);

                  setPendingCommit({
                    action: CommitAction.EnterKey,
                    value: currentSuggestion
                      ? currentSuggestion.name
                      : (event.target as any).value // Bad typings
                  });
                  break;
              }
            }}
            {...props}
          />
          {showSuggestions && suggestions.length > 0 && (
            <SuggestionsContainer>
              <SuggestionsList>
                {suggestions.map((s, i) => (
                  <Suggestion
                    key={i}
                    isHighlighted={i === focusedSuggestion}
                    onMouseOver={() => setFocusedSuggestion(i)}
                    onMouseDown={event => event.preventDefault()}
                    onClick={() => {
                      setValue(s.name);
                      setPendingCommit({
                        action: CommitAction.Click,
                        value: s.name
                      });
                    }}
                  >
                    {s.name}
                  </Suggestion>
                ))}
              </SuggestionsList>
            </SuggestionsContainer>
          )}
        </TypeaheadWrapper>
      </Container>
    );
  }
);

export default CategoryInput;
