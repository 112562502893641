import { observable } from "mobx";
import React from "react";

export interface IBank {
  id: string;
  name: string;
  plaidInstitutionId: string;
}

export interface IBankAccount {
  id: string;
  bankId: string;
  plaidId: string;
  name: string;
  mask: string;
  type: string;
  subtype: string;
  enabled: boolean;
}

export interface ITransaction {
  id: string;
  bankAccountId: string;
  amount: number;
  currencyCode: string;
  date: string;
  tags: string[];
  name: string;
  category: string | null;
  ignore: boolean;
}

export interface ICategory {
  name: string;
}

export class BankDataStore {
  @observable public banks: IBank[] = [];
  @observable public accounts: IBankAccount[] = [];
  @observable public transactions: ITransaction[] = [];
  @observable public categories: ICategory[] = [];

  public addBank(bank: IBank) {
    // TODO: ensure we don't dupe?
    this.banks.push(bank);
  }

  public addAccounts(accounts: IBankAccount[]) {
    // TODO: ensure we don't dupe?
    this.accounts = this.accounts.concat(accounts);
  }

  public accountsInBank(bank: IBank) {
    return this.accounts.filter(a => a.bankId === bank.id);
  }

  public addCategory(category: string) {
    if (category === "") return;

    // TODO: case agnostic?
    if (this.categories.findIndex(c => c.name === category) >= 0) return;

    this.categories.push({ name: category });
  }
}

const BankDataContext = React.createContext(new BankDataStore());

export default BankDataContext;
