import React, { useContext, useState } from "react";
import { getCategoryReports, ICategoryReportItem } from "../api/budgets";
import UserContext from "../contexts/UserContext";
import useAsyncEffect from "../util/useAsyncEffect";
import styled from "@emotion/styled";
import { formatMoney } from "accounting";
import { format, startOfMonth, endOfMonth } from "date-fns";
import MonthPicker from "../components/atoms/MonthPicker";
import LoadingSpinner from "../components/atoms/LoadingSpinner";

const Container = styled.div`
  max-width: 50rem;
  margin: 0 auto;
`;

// TODO: refactor out shared styled components
const PageHeading = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Item = styled.div`
  display: flex;
  padding: 1rem 1rem;
  align-items: center;
  font-size: 1.5rem;
`;

const Amount = styled.div<{ isCredit: boolean }>`
  min-width: 10rem;
  text-align: right;
  color: ${({ isCredit }) => (isCredit ? "green" : "inherit")};
`;

const CategoryName = styled.div`
  flex-grow: 1;
`;

const StyledMonthPicker = styled(MonthPicker)`
  margin-bottom: 0.5rem;
`;

const ReportsScreen = () => {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState<ICategoryReportItem[]>([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  useAsyncEffect(async () => {
    setLoading(true);

    const result = await getCategoryReports(user.token, {
      startDate: format(startOfMonth(currentMonth), "y-MM-dd"),
      endDate: format(endOfMonth(currentMonth), "y-MM-dd")
    });

    result.ifSuccess(r => setReportData(r));

    setLoading(false);
  }, [user.token, currentMonth]);

  const other = reportData.find(c => c.name === null);
  const categories = reportData
    .filter(c => c.name !== null)
    .sort((a, b) => (a.amount < b.amount ? 1 : -1));

  return (
    <Container>
      <PageHeading>Reporting</PageHeading>
      <StyledMonthPicker
        date={currentMonth}
        max={new Date()}
        onChange={newMonth => setCurrentMonth(newMonth)}
      />
      {loading && <LoadingSpinner />}
      {!loading &&
        categories.map(report => (
          <Item key={report.name}>
            <CategoryName>{report.name}</CategoryName>
            <Amount isCredit={report.amount < 0}>
              {report.amount < 0 && "+ "}
              {formatMoney(Math.abs(report.amount))}
            </Amount>
          </Item>
        ))}
      {!loading && other && (
        <Item>
          <CategoryName>Other</CategoryName>
          <Amount isCredit={other.amount < 0}>
            {other.amount < 0 && "+ "}
            {formatMoney(Math.abs(other.amount))}
          </Amount>
        </Item>
      )}
    </Container>
  );
};

export default ReportsScreen;
