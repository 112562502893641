import React from "react";
import styled from "@emotion/styled";
import { format, addMonths, startOfMonth } from "date-fns";

const Container = styled.div`
  line-height: 1.5rem;
  display: flex;
`;

const CurrentMonth = styled.div`
  padding: 0 0.5rem;
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
`;

const Button = styled.button`
  border: 1px solid #c0c0c0;
  font-size: 0.7rem;
  line-height: inherit;
  padding: 0 0.5rem;
`;

const PrevButton = styled(Button)`
  border-right: 0;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
`;

const NextButton = styled(Button)`
  border-left: 0;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
`;

interface IProps {
  date: Date;
  max?: Date;
  onChange: (newDate: Date) => void;
  className?: string;
}

const MonthPicker = ({ date, max, onChange, className }: IProps) => {
  return (
    <Container className={className}>
      <PrevButton
        onClick={() => onChange(addMonths(date, -1))}
        title="Previous"
      >
        ◀
      </PrevButton>
      <CurrentMonth>{format(date, "MMM y")}</CurrentMonth>
      <NextButton
        disabled={max && startOfMonth(date) >= startOfMonth(max)}
        onClick={() => onChange(addMonths(date, 1))}
        title="Next"
      >
        ▶
      </NextButton>
    </Container>
  );
};

export default MonthPicker;
