import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import UserContext from "../../contexts/UserContext";
import { Redirect, Link } from "react-router-dom";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  color: #a0a0a0;
  padding: 0.5rem 1rem;
`;

const MainNav = styled.div`
  flex-grow: 1;
`;

const AccountNav = styled.div``;

const linkStyling = `
  color: inherit;
  text-decoration: underline;
`;

const StyledLink = styled(Link)`
  ${linkStyling}
  margin-right: 0.5rem;
`;

const LinkButton = styled.button`
  ${linkStyling}
  border: none;
  background: none;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
  margin-left: 0.5rem;
`;

const LoggedInHeader = observer(() => {
  const user = useContext(UserContext);
  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  if (redirectTo) return <Redirect to={redirectTo} />;

  return (
    <Container>
      <MainNav>
        <StyledLink to="/transactions">Transactions</StyledLink>
        <StyledLink to="/reports">Reports</StyledLink>
        <StyledLink to="/budget">Budget</StyledLink>
        <StyledLink to="/accounts">Accounts</StyledLink>
      </MainNav>
      <AccountNav>
        {user.userName}
        <LinkButton
          onClick={() => {
            user.logout();
            setRedirectTo("/");
          }}
        >
          Logout
        </LinkButton>
      </AccountNav>
    </Container>
  );
});

export default LoggedInHeader;
