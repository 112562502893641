import { apiUrl, requestHeaders } from "./util";
import Result from "../util/Result";
import { IMonthlyBudgetLine } from "../contexts/BudgetsContext";

interface IGetBudgetLineItemsRequest {
  month: string;
}

export async function getMonthlyBudgetLines(
  token: string,
  request: IGetBudgetLineItemsRequest
) {
  const result = new Result<IMonthlyBudgetLine[], any>();

  const url = new URL(`${apiUrl}/monthlyBudgetLines`);
  url.search = new URLSearchParams(request as any).toString();

  const response = await fetch(url.toString(), {
    method: "GET",
    headers: requestHeaders({ token: token })
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export interface ICreateMonthlyBudgetLineRequest {
  amount: number;
  categories: string[];
  month: string;
  carryover: boolean;
}
export async function createMonthlyBudgetLine(
  token: string,
  request: ICreateMonthlyBudgetLineRequest
) {
  const result = new Result<IMonthlyBudgetLine, any>();

  const response = await fetch(`${apiUrl}/monthlyBudgetLines`, {
    method: "POST",
    headers: requestHeaders({ token: token }),
    body: JSON.stringify(request)
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export async function updateMonthlyBudgetLine(
  token: string,
  id: string,
  request: Partial<ICreateMonthlyBudgetLineRequest>
) {
  const result = new Result<IMonthlyBudgetLine, any>();

  const response = await fetch(`${apiUrl}/monthlyBudgetLines/${id}`, {
    method: "PATCH",
    headers: requestHeaders({ token: token }),
    body: JSON.stringify(request)
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export async function deleteMonthlyBudgetLine(token: string, id: string) {
  const result = new Result<void, any>();

  const response = await fetch(`${apiUrl}/monthlyBudgetLines/${id}`, {
    method: "DELETE",
    headers: requestHeaders({ token: token })
  });

  if (response.status === 200) {
    return result.succeed();
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

interface IGetCategoryReportsRequest {
  startDate: string;
  endDate: string;
}
export interface ICategoryReportItem {
  name: string;
  amount: number;
}
export async function getCategoryReports(
  token: string,
  request: IGetCategoryReportsRequest
) {
  const result = new Result<ICategoryReportItem[], any>();

  const url = new URL(`${apiUrl}/categoryReports`);
  url.search = new URLSearchParams(request as any).toString();

  const response = await fetch(url.toString(), {
    method: "GET",
    headers: requestHeaders({ token: token })
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}
