import { observable } from "mobx";
import React from "react";
import { ICategoryReportItem } from "../api/budgets";

export interface IMonthlyBudgetLine {
  id: string;
  amount: number;
  carryoverAmount: number;
  categories: string[];
  month: string;
  carryover: boolean;
}

export class BudgetsStore {
  @observable public monthlyLines: IMonthlyBudgetLine[] = [];
  @observable public monthlyReport: ICategoryReportItem[] = [];

  public addMonthlyLine(line: IMonthlyBudgetLine) {
    // TODO: ensure we don't dupe?
    this.monthlyLines.push(line);
  }

  public updateMonthlyLine(line: IMonthlyBudgetLine) {
    const index = this.monthlyLines.findIndex(l => l.id === line.id);
    if (index < 0) return;

    this.monthlyLines[index] = line;
  }

  public deleteMonthlyLine(id: string) {
    const index = this.monthlyLines.findIndex(l => l.id === id);
    if (index < 0) return;

    this.monthlyLines.splice(index, 1);
  }
}

const BudgetsContext = React.createContext(new BudgetsStore());

export default BudgetsContext;
