import { apiUrl, requestHeaders } from "./util";
import Result from "../util/Result";

export interface IRegisterRequest {
  userName: string;
  password: string;
  inviteCode: string;
}
export interface IProfileResponse {
  userName: string;
}
export async function register(request: IRegisterRequest) {
  const result = new Result<IProfileResponse, any>();

  const response = await fetch(`${apiUrl}/users`, {
    method: "POST",
    headers: requestHeaders(),
    body: JSON.stringify(request)
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export interface ILoginRequest {
  userName: string;
  password: string;
}
export interface ILoginResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}
export async function login({ userName, password }: ILoginRequest) {
  const result = new Result<ILoginResponse, any>();

  const formData = new FormData();
  formData.append("grant_type", "password");
  formData.append("client_id", "BudgetingWeb");
  formData.append("username", userName);
  formData.append("password", password);

  const response = await fetch(`${apiUrl}/connect/token`, {
    method: "POST",
    body: formData
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export async function getMe(token: string) {
  const result = new Result<IProfileResponse, any>();

  const response = await fetch(`${apiUrl}/users/me`, {
    method: "GET",
    headers: requestHeaders({ token: token })
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else {
    return result.fail(null);
  }
}
