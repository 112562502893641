import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const HomeScreen = observer(() => {
  return (
    <>
      <h1>World!</h1>
      <Link to="/accounts">Link Accounts</Link>
    </>
  );
});

export default HomeScreen;
