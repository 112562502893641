import React, { useState, useContext } from "react";
import styled from "@emotion/styled";
import { formatMoney } from "accounting";
import {
  updateMonthlyBudgetLine,
  deleteMonthlyBudgetLine
} from "../../api/budgets";
import BudgetLineForm from "./BudgetLineForm";
import IconButton from "../atoms/IconButton";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import BudgetsContext, {
  IMonthlyBudgetLine
} from "../../contexts/BudgetsContext";
import UserContext from "../../contexts/UserContext";
import Hashtag from "../atoms/Hashtag";
import { observer } from "mobx-react-lite";

const Container = styled.div`
  padding: 1rem 0;
`;

const Info = styled.div`
  display: flex;
`;

const Title = styled.div`
  flex-grow: 1;
`;

const Category = styled.span`
  font-weight: bold;
`;

const Amount = styled.div`
  min-width: 10rem;
  text-align: right;
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const AmountSecondary = styled.span`
  color: #c0c0c0;
`;

const Bar = styled.div<{ width: number; warnOverage: boolean }>`
  height: 0.2rem;
  margin-top: 0.5rem;
  background-color: ${({ warnOverage }) =>
    warnOverage ? "#d24421" : "#71994f"};
  width: ${({ width }) => width * 100}%;
  opacity: 0.4;
`;

interface IProps {
  budgetLine: IMonthlyBudgetLine;
}
const BudgetListItem = observer(({ budgetLine }: IProps) => {
  const [editing, setEditing] = useState(false);
  const budgetData = useContext(BudgetsContext);
  const user = useContext(UserContext);

  let amountSpent = budgetLine.categories.reduce((memo, category) => {
    const categoryReportItem = budgetData.monthlyReport.find(
      c => c.name === category
    );
    if (categoryReportItem) {
      return memo + categoryReportItem.amount;
    } else {
      return memo;
    }
  }, 0);

  if (amountSpent < 0) amountSpent = 0;

  const totalAmount = budgetLine.amount + budgetLine.carryoverAmount;

  if (editing) {
    return (
      <BudgetLineForm
        initialValues={{
          category: budgetLine.categories.join(", "),
          amount: budgetLine.amount.toString(),
          carryover: budgetLine.carryover
        }}
        onSubmit={async (
          { category, amount, carryover },
          { setSubmitting }
        ) => {
          const parsedAmount = parseFloat(amount);

          const result = await updateMonthlyBudgetLine(
            user.token,
            budgetLine.id,
            {
              categories: [category],
              amount: isNaN(parsedAmount) ? 0 : parsedAmount,
              carryover
            }
          );

          result.ifSuccess(response => {
            budgetData.updateMonthlyLine(response);
            setEditing(false);
          });

          // TODO: error handling
          result.ifError(error => setSubmitting(false));
        }}
        onDelete={async () => {
          const result = await deleteMonthlyBudgetLine(
            user.token,
            budgetLine.id
          );

          result.ifSuccess(() => budgetData.deleteMonthlyLine(budgetLine.id));
          // TODO: error handling
        }}
      />
    );
  } else {
    return (
      <Container key={budgetLine.id}>
        <Info>
          <Title>
            <Hashtag />
            <Category>{budgetLine.categories.join(", ")} </Category>
          </Title>
          <Amount>
            <Strong>{formatMoney(amountSpent, { precision: 0 })}</Strong>
            <AmountSecondary> of </AmountSecondary>
            {formatMoney(totalAmount, { precision: 0 })}
          </Amount>
          <IconButton icon={faPen} onClick={() => setEditing(true)} />
        </Info>
        <Bar
          width={amountSpent < totalAmount ? amountSpent / totalAmount : 1}
          warnOverage={amountSpent > totalAmount}
        />
      </Container>
    );
  }
});

export default BudgetListItem;
