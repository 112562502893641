import React, { useContext, useState } from "react";
import { IBankAccount } from "../../contexts/BankDataContext";
import { updateBankAccount } from "../../api/banks";
import UserContext from "../../contexts/UserContext";

interface IProps {
  account: IBankAccount;
}
const LinkedAccount = ({ account }: IProps) => {
  const user = useContext(UserContext);
  const [saving, setSaving] = useState(false);

  return (
    <div>
      <h4>
        {account.name} ({account.subtype} {account.mask})
        <input
          type="checkbox"
          disabled={saving}
          checked={account.enabled}
          onChange={async event => {
            account.enabled = event.target.checked;
            setSaving(true);
            const result = await updateBankAccount(
              user.token,
              account.id,
              account
            );
            result.ifSuccess(() => setSaving(false));
            // TODO: handle errors
          }}
        />
      </h4>
    </div>
  );
};

export default LinkedAccount;
