import React from "react";
import styled from "@emotion/styled";

interface IProps {
  paddingBottom?: string;
}

const Container = styled.span<IProps>`
  margin-right: 0.2rem;
  padding-bottom: ${({ paddingBottom = "1px" }) => paddingBottom};
  color: #c0c0c0;
`;

const Hashtag = (props: IProps) => <Container {...props}>#</Container>;

export default Hashtag;
