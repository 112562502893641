import React from "react";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface IProps {
  size: number;
  color: string;
  sizeUnit: string;
}

const Wrapper = styled.div<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => `${props.size}${props.sizeUnit}`};
  height: ${props => `${props.size}${props.sizeUnit}`};
  border: ${props => `${props.size / 5}${props.sizeUnit}`} solid
    ${props => props.color};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${rotate} 0.75s linear infinite;
`;

function LoadingSpinner({
  size = 30,
  color = "#000",
  sizeUnit = "px"
}: Partial<IProps>) {
  return <Wrapper size={size} color={color} sizeUnit={sizeUnit} />;
}

export default LoadingSpinner;
