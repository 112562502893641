import { useEffect, useRef, Ref, MutableRefObject } from "react";

export default function useForwardRefSpy<T>(ref: Ref<T>) {
  const spy = useRef<T>(null);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === "function") {
      ref(spy.current);
    } else {
      (ref as MutableRefObject<T | null>).current = spy.current;
    }
  }, [ref]);

  return spy;
}
