export default function animatedScrollTo(finalY: number) {
  let currentY = window.scrollY;
  const diffY = Math.abs(finalY - currentY);
  const stepY = diffY / 12;

  function stepUp() {
    if (currentY <= finalY) return;

    let step = stepY;
    if (currentY - finalY < step) step = currentY - finalY;
    currentY -= stepY;
    window.scrollTo(0, currentY);

    requestAnimationFrame(stepUp);
  }

  function stepDown() {
    if (currentY >= finalY) return;

    let step = stepY;
    if (finalY - currentY < step) step = finalY - currentY;
    currentY += step;
    window.scrollTo(0, currentY);

    requestAnimationFrame(stepDown);
  }

  const stepFn = finalY > currentY ? stepDown : stepUp;
  requestAnimationFrame(stepFn);
}
