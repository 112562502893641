import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import PlaidLink from "react-plaid-link";
import BankDataContext, {
  IBankAccount,
  IBank
} from "../contexts/BankDataContext";
import {
  createBank,
  getBanks,
  createBankAccount,
  getBankAccounts
} from "../api/banks";
import UserContext from "../contexts/UserContext";
import useAsyncEffect from "../util/useAsyncEffect";
import LinkedAccount from "../components/organisms/LinkedAccount";

const plaidKey = process.env.REACT_APP_PLAID_PUBLIC_KEY;
type PlaidEnv = "tartan" | "sandbox" | "development" | "production" | undefined;
const plaidEnv = process.env.REACT_APP_PLAID_ENV as PlaidEnv;

const AccountsScreen = observer(() => {
  const user = useContext(UserContext);
  const bankData = useContext(BankDataContext);

  useAsyncEffect(async () => {
    const result = await getBanks(user.token);
    result.ifSuccess(banks => (bankData.banks = banks));
  }, [user.token]);
  useAsyncEffect(async () => {
    const result = await getBankAccounts(user.token);
    result.ifSuccess(accounts => (bankData.accounts = accounts));
  }, [user.token]);

  return (
    <>
      {bankData.accounts.map(account => (
        <LinkedAccount account={account} key={account.id} />
      ))}

      {plaidKey && plaidEnv && (
        <PlaidLink
          clientName="Budgeting"
          env={plaidEnv}
          product={["auth", "transactions"]}
          publicKey={plaidKey}
          onExit={() => {}}
          onSuccess={async (publicToken, metadata) => {
            let existingBank = bankData.banks.find(
              b => b.plaidInstitutionId === metadata.institution.institution_id
            );

            if (existingBank) {
              // TODO: what to do here? maybe refresh accounts for prior access_token and
              // let user select from that set?
            } else {
              const result = await createBank(user.token, {
                publicToken,
                plaidInstitutionId: metadata.institution.institution_id,
                name: metadata.institution.name
              });

              result.ifSuccess(async bank => {
                bankData.addBank(bank);

                const accountResults = await Promise.all(
                  metadata.accounts.map(async accountData => {
                    return createBankAccount(user.token, {
                      bankId: (bank as IBank).id, // Coerce b/c compiler can't figure out above undefined check
                      plaidId: accountData.id,
                      mask: accountData.mask,
                      name: accountData.name,
                      type: accountData.type,
                      subtype: accountData.subtype,
                      enabled: false
                    });
                  })
                );

                const accounts = accountResults
                  .filter(r => r.success)
                  .map(r => r.value as IBankAccount);
                bankData.addAccounts(accounts);

                // TODO: error handling
                // accountResults.filter(r => !r.success)
              });

              // TODO: error handling
            }
          }}
        >
          Open Link and connect your bank!
        </PlaidLink>
      )}
    </>
  );
});

export default AccountsScreen;
