import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Button = styled.button`
  border: none;
  background: none;
  padding: 0;
  outline: none;
  text-align: center;
  width: 1.5rem;
  cursor: pointer;
  line-height: 1rem;

  &:focus svg path {
    fill: #0999e0;
  }
`;

interface IProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: IconProp;
  color?: string;
}
const IconButton = ({ icon, color = "#c0c0c0", ...props }: IProps) => {
  return (
    <Button type="button" {...props}>
      <FontAwesomeIcon icon={icon} color={color} />
    </Button>
  );
};

export default IconButton;
