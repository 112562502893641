import React, { useContext, useState, useRef, useEffect } from "react";
import BankDataContext, { ITransaction } from "../../contexts/BankDataContext";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import styled from "@emotion/styled";
import CategoryInput, { CommitAction } from "../molecules/CategoryInput";
import { updateTransaction } from "../../api/banks";
import UserContext from "../../contexts/UserContext";
import { formatMoney } from "accounting";
import animatedScrollTo from "../../util/animatedScrollTo";
import VisibilityToggle from "../atoms/VisibilityToggle";

enum BorderType {
  Outer,
  Inner
}

const Container = styled.div<{ border: BorderType }>`
  display: flex;
  ${({ border }) =>
    border === BorderType.Outer && "border-top: 1px solid #c0c0c0;"}
`;

const TransactionDate = styled.div`
  width: 5rem;
  min-width: 5rem;
  padding: 0.8rem 0 0.6rem;
`;

const Info = styled.div<{ border: BorderType; ignored: boolean }>`
  flex-grow: 1;
  min-width: 0; // Hack: https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
  ${({ border }) =>
    border === BorderType.Inner && "border-top: 1px solid #e7e7e7;"}
  padding: 0.8rem 0 0.6rem;
  color: ${({ ignored }) => (ignored ? "#c0c0c0" : "inherit")};
`;

const Description = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.4rem;
`;

const Meta = styled.div<{ border: BorderType; ignored: boolean }>`
  min-width: 10rem;
  text-align: right;
  ${({ border }) =>
    border === BorderType.Inner && "border-top: 1px solid #e7e7e7;"}
  padding: 0.8rem 0 0.6rem;
  color: ${({ ignored }) => (ignored ? "#c0c0c0" : "inherit")};
`;

const Amount = styled.div<{ isCredit: boolean }>`
  color: ${({ isCredit }) => (isCredit ? "green" : "inherit")};
  margin-bottom: 0.4rem;
`;

const StyledCategoryInput = styled(CategoryInput)`
  width: 15rem;
  min-width: 15rem;
`;

export interface FocusFieldRequest {
  field: FocusableField;
}

export enum FocusableField {
  Category
}

interface IProps {
  transaction: ITransaction;
  focusField?: FocusFieldRequest;
  showDate: boolean;
  advanceListFocus: (transactionId: string, field: FocusableField) => void;
}
const TransactionListItem = observer(
  ({ transaction, focusField, advanceListFocus, showDate }: IProps) => {
    const bankData = useContext(BankDataContext);
    const user = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const categoryInputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (focusField === undefined) return;

      // When user is navigating via enter-key, let's override default browser
      // auto-scrolling behavior to scroll a little earlier so last input's
      // dropdown is visible
      if (containerRef.current) {
        const viewportOffset =
          containerRef.current.offsetTop - window.pageYOffset;
        const viewportHeight = window.innerHeight;

        if (viewportOffset > viewportHeight * 0.8) {
          const destinationY =
            containerRef.current.offsetTop - viewportHeight * 0.2;
          animatedScrollTo(destinationY);
        }
      }

      if (focusField.field === FocusableField.Category) {
        categoryInputRef.current?.focus();
      }
    }, [focusField, categoryInputRef]);

    const date = new Date(transaction.date);
    const border = showDate ? BorderType.Outer : BorderType.Inner;

    return (
      <Container ref={containerRef} border={border}>
        <TransactionDate>{showDate && format(date, "MMM d")}</TransactionDate>
        <Info border={border} ignored={transaction.ignore}>
          <Description>{transaction.name}</Description>
          <StyledCategoryInput
            value={transaction.category || ""}
            disabled={saving}
            ref={categoryInputRef}
            onCommit={async (newCategory, action) => {
              if (action === CommitAction.EnterKey)
                advanceListFocus(transaction.id, FocusableField.Category);

              if (
                newCategory === transaction.category ||
                (newCategory === "" && transaction.category === null)
              )
                return;

              setSaving(true);

              transaction.category = newCategory;
              bankData.addCategory(newCategory);

              // TODO: error handling
              await updateTransaction(user.token, transaction.id, {
                category: newCategory
              });

              setSaving(false);
            }}
          />
        </Info>
        <Meta border={border} ignored={transaction.ignore}>
          <Amount isCredit={transaction.amount < 0}>
            {transaction.amount < 0 && "+ "}
            {formatMoney(Math.abs(transaction.amount))}
          </Amount>
          <VisibilityToggle
            value={!transaction.ignore}
            onClick={async () => {
              transaction.ignore = !transaction.ignore;

              // TODO: error handling
              await updateTransaction(user.token, transaction.id, {
                ignore: transaction.ignore
              });
            }}
          />
        </Meta>
      </Container>
    );
  }
);

export default TransactionListItem;
