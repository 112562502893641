import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "./IconButton";

interface IProps {
  value: boolean;
  onClick: () => void;
  className?: string;
}
const VisibilityToggle = ({ value, onClick, className }: IProps) => {
  return (
    <IconButton
      onClick={onClick}
      className={className}
      icon={value ? faEye : faEyeSlash}
    />
  );
};

export default VisibilityToggle;
