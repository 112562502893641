import React, { useState } from "react";
import { create as createMobxPersist } from "mobx-persist";
import { observer, useLocalStore } from "mobx-react-lite";
import { BrowserRouter as Router, Route } from "react-router-dom";
import UserContext, { UserStore } from "./contexts/UserContext";
import useAsyncEffect from "./util/useAsyncEffect";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import AnonymousRoute from "./components/atoms/AnonymousRoute";
import LoggedInHeader from "./components/organisms/LoggedInHeader";
import AuthorizedRoute from "./components/atoms/AuthorizedRoute";
import AccountsScreen from "./screens/AccountsScreen";
import TransactionsScreen from "./screens/TransactionsScreen";
import BankDataContext, { BankDataStore } from "./contexts/BankDataContext";
import styled from "@emotion/styled";
import reset from "react-style-reset";
import { injectGlobal } from "emotion";
import ReportsScreen from "./screens/ReportsScreen";
import BudgetScreen from "./screens/BudgetsScreen";

injectGlobal(reset);

const AppContainer = styled.div`
  font-family: "Roboto", sans-serif;

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    font-family: "Roboto", sans-serif;
    color: inherit;
    width: 100%;

    &:disabled {
      background: none;
    }
  }
`;

const hydrate = createMobxPersist();
enum MobxHydrateState {
  None,
  Hydrating,
  Hydrated
}

const App = observer(() => {
  const user = useLocalStore(() => new UserStore());
  const bankData = useLocalStore(() => new BankDataStore());
  const [hydrateState, setHydrateState] = useState(MobxHydrateState.None);

  useAsyncEffect(async () => {
    if (hydrateState === MobxHydrateState.None) {
      setHydrateState(MobxHydrateState.Hydrating);
      await hydrate("user", user);
      setHydrateState(MobxHydrateState.Hydrated);
    }
  }, []);

  if (hydrateState === MobxHydrateState.Hydrated) {
    return (
      <AppContainer>
        <UserContext.Provider value={user}>
          <BankDataContext.Provider value={bankData}>
            <Router>
              {user.isLoggedIn() ? <LoggedInHeader /> : null}
              <Route
                path="/"
                exact
                component={user.isLoggedIn() ? HomeScreen : LoginScreen}
              />
              <AnonymousRoute path="/login" exact component={LoginScreen} />
              <AnonymousRoute
                path="/register"
                exact
                component={RegisterScreen}
              />
              <AuthorizedRoute
                path="/accounts"
                exact
                component={AccountsScreen}
              />
              <AuthorizedRoute
                path="/transactions"
                exact
                component={TransactionsScreen}
              />
              <AuthorizedRoute
                path="/reports"
                exact
                component={ReportsScreen}
              />
              <AuthorizedRoute path="/budget" exact component={BudgetScreen} />
            </Router>
          </BankDataContext.Provider>
        </UserContext.Provider>
      </AppContainer>
    );
  } else {
    return null;
  }
});

export default App;
