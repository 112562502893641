import React, { useContext } from "react";
import { Formik } from "formik";
import UserContext from "../contexts/UserContext";
import { register, login } from "../api/user";
import { Link } from "react-router-dom";

const RegisterScreen = () => {
  const user = useContext(UserContext);

  return (
    <>
      <h2>Sign Up</h2>
      <Formik
        initialValues={{ userName: "", password: "", inviteCode: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          const result = await register(values);

          result.ifSuccess(async response => {
            const loginResult = await login({
              userName: values.userName,
              password: values.password
            });

            loginResult.ifSuccess(loginResponse => {
              user.setProfile(response);
              user.setToken(loginResponse);
            });

            // TODO: error handling
            loginResult.ifError(() => setSubmitting(false));
          });

          // TODO: error handling
          result.ifError(error => setSubmitting(false));
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="userName">Username</label>
              <input
                type="text"
                name="userName"
                id="userName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userName}
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </div>
            <div>
              <label htmlFor="inviteCode">Invite Code</label>
              <input
                type="text"
                name="inviteCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.inviteCode}
              />
            </div>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </form>
        )}
      </Formik>
      <Link to="/login">Login</Link>
    </>
  );
};

export default RegisterScreen;
