import React, { useContext, useState, useEffect, useMemo } from "react";
import UserContext from "../contexts/UserContext";
import useAsyncEffect from "../util/useAsyncEffect";
import styled from "@emotion/styled";
import { format, startOfMonth, endOfMonth } from "date-fns";
import MonthPicker from "../components/atoms/MonthPicker";
import {
  getMonthlyBudgetLines,
  createMonthlyBudgetLine,
  getCategoryReports,
  ICategoryReportItem
} from "../api/budgets";
import Result from "../util/Result";
import { uniq } from "ramda";
import LoadingSpinner from "../components/atoms/LoadingSpinner";
import BudgetListItem from "../components/organisms/BudgetListItem";
import BudgetLineForm from "../components/organisms/BudgetLineForm";
import { getCategories } from "../api/banks";
import BankDataContext, { ICategory } from "../contexts/BankDataContext";
import BudgetsContext, {
  BudgetsStore,
  IMonthlyBudgetLine
} from "../contexts/BudgetsContext";
import { useLocalStore, observer } from "mobx-react-lite";
import { formatMoney } from "accounting";
import Hashtag from "../components/atoms/Hashtag";
import { useHistory, useLocation } from "react-router-dom";
import { parseDateAsLocal } from "../util/date";

const Container = styled.div`
  max-width: 50rem;
  margin: 0 auto;
`;

// TODO: refactor out shared styled components
const PageHeading = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const StyledMonthPicker = styled(MonthPicker)`
  margin-bottom: 0.5rem;
`;

const AddNewButton = styled.button`
  background: none;
  display: block;
  width: 100%;
  text-align: center;
  border: 1px dashed #c0c0c0;
  border-radius: 1rem;
  padding: 1rem;
  font-size: inherit;
  color: #a0a0a0;
  cursor: pointer;
  outline: none;

  &:focus {
    border-color: #0999e0;
  }
`;

const UnbudgetedHeading = styled.h5`
  font-size: 1rem;
  font-weight: bold;
  margin: 2rem 0 1rem;
`;

const UnbudgetedListItem = styled.div`
  margin: 1rem 0;
`;

const BudgetScreen = observer(() => {
  const user = useContext(UserContext);
  const bankData = useContext(BankDataContext);
  const budgetData = useLocalStore(() => new BudgetsStore());
  const [loading, setLoading] = useState(true);
  const [showNew, setShowNew] = useState(false);

  const defaultMonth = useMemo(() => startOfMonth(new Date()), []);
  const [currentMonth, setCurrentMonth] = useState(defaultMonth);

  const history = useHistory();
  const location = useLocation();

  const navigateToMonth = (date: Date) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("month", format(date, "y-MM-dd"));

    history.push({
      pathname: location.pathname,
      search: "?" + queryParams
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryMonth = queryParams.get("month");

    const targetDate = queryMonth ? parseDateAsLocal(queryMonth) : defaultMonth;
    setCurrentMonth(startOfMonth(targetDate));
  }, [location.search, defaultMonth]);

  useAsyncEffect(async () => {
    setLoading(true);

    // This is really unfortunate, but a consequence of TypeScript's
    // insufficient Promise.all type signature.
    const [
      reportResult,
      budgetLinesResult,
      categoriesResult
    ] = await Promise.all<
      Result<ICategoryReportItem[], any>,
      Result<IMonthlyBudgetLine[], any>,
      Result<ICategory[], any>
    >([
      getCategoryReports(user.token, {
        startDate: format(startOfMonth(currentMonth), "y-MM-dd"),
        endDate: format(endOfMonth(currentMonth), "y-MM-dd")
      }),
      getMonthlyBudgetLines(user.token, {
        month: format(startOfMonth(currentMonth), "y-MM-dd")
      }),
      getCategories(user.token)
    ]);

    reportResult.ifSuccess(r => (budgetData.monthlyReport = r));
    budgetLinesResult.ifSuccess(b => (budgetData.monthlyLines = b));
    categoriesResult.ifSuccess(c => (bankData.categories = c));

    setLoading(false);
  }, [user.token, currentMonth]);

  const budgetedCategories = uniq(
    budgetData.monthlyLines.flatMap(b => b.categories)
  );
  const unbudgetedCategoryReports = budgetData.monthlyReport.filter(
    c =>
      c.name !== null && c.amount > 0 && budgetedCategories.indexOf(c.name) < 0
  );
  const uncategorizedExpensesReport = budgetData.monthlyReport.find(
    c => c.name === null
  );
  const unbudgetedTotal =
    unbudgetedCategoryReports.reduce((memo, c) => memo + c.amount, 0) +
    (uncategorizedExpensesReport ? uncategorizedExpensesReport.amount : 0);

  return (
    <BudgetsContext.Provider value={budgetData}>
      <Container>
        <PageHeading>Budget</PageHeading>
        <StyledMonthPicker
          date={currentMonth}
          max={new Date()}
          onChange={newMonth => navigateToMonth(newMonth)}
        />
        {loading && <LoadingSpinner />}
        {!loading &&
          budgetData.monthlyLines.map(budgetLine => {
            return (
              <BudgetListItem key={budgetLine.id} budgetLine={budgetLine} />
            );
          })}
        {!loading && !showNew && (
          <AddNewButton onClick={() => setShowNew(true)}>
            Add new budget line
          </AddNewButton>
        )}
        {showNew && (
          <BudgetLineForm
            initialValues={{ category: "", amount: "", carryover: false }}
            onSubmit={async (
              { category, amount, carryover },
              { setSubmitting }
            ) => {
              const parsedAmount = parseFloat(amount);

              const result = await createMonthlyBudgetLine(user.token, {
                categories: [category],
                amount: isNaN(parsedAmount) ? 0 : parsedAmount,
                month: format(startOfMonth(currentMonth), "y-MM-dd"),
                carryover
              });

              result.ifSuccess(async response => {
                budgetData.addMonthlyLine(response);
                setSubmitting(false);
                setShowNew(false);
              });

              // TODO: error handling
              result.ifError(error => setSubmitting(false));
            }}
          />
        )}
        {!loading && unbudgetedTotal > 0 && (
          <div>
            <UnbudgetedHeading>
              Unbudgeted {formatMoney(unbudgetedTotal, { precision: 0 })}
            </UnbudgetedHeading>
            {unbudgetedCategoryReports.map(r => (
              <UnbudgetedListItem key={r.name}>
                <Hashtag />
                {r.name} {formatMoney(r.amount, { precision: 0 })}
              </UnbudgetedListItem>
            ))}
            {uncategorizedExpensesReport && (
              <UnbudgetedListItem>
                Uncategorized{" "}
                {formatMoney(uncategorizedExpensesReport.amount, {
                  precision: 0
                })}
              </UnbudgetedListItem>
            )}
          </div>
        )}
      </Container>
    </BudgetsContext.Provider>
  );
});

export default BudgetScreen;
