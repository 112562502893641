import { apiUrl, requestHeaders } from "./util";
import Result from "../util/Result";
import {
  IBank,
  IBankAccount,
  ITransaction,
  ICategory
} from "../contexts/BankDataContext";

export interface ICreateBankRequest {
  publicToken: string;
  plaidInstitutionId: string;
  name: string;
}
export async function createBank(token: string, request: ICreateBankRequest) {
  const result = new Result<IBank, any>();

  const response = await fetch(`${apiUrl}/banks`, {
    method: "POST",
    headers: requestHeaders({ token: token }),
    body: JSON.stringify(request)
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export async function getBanks(token: string) {
  const result = new Result<IBank[], any>();

  const response = await fetch(`${apiUrl}/banks`, {
    method: "GET",
    headers: requestHeaders({ token: token })
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export interface ICreateOrUpdateBankAccountRequest {
  bankId: string;
  plaidId: string;
  mask: string;
  name: string;
  type: string;
  subtype: string;
  enabled: boolean;
}
export async function createBankAccount(
  token: string,
  request: ICreateOrUpdateBankAccountRequest
) {
  const result = new Result<IBankAccount, any>();

  const response = await fetch(`${apiUrl}/bankAccounts`, {
    method: "POST",
    headers: requestHeaders({ token: token }),
    body: JSON.stringify(request)
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export async function updateBankAccount(
  token: string,
  id: string,
  request: ICreateOrUpdateBankAccountRequest
) {
  const result = new Result<IBankAccount, any>();

  const response = await fetch(`${apiUrl}/bankAccounts/${id}`, {
    method: "PATCH",
    headers: requestHeaders({ token: token }),
    body: JSON.stringify(request)
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export async function getBankAccounts(token: string) {
  const result = new Result<IBankAccount[], any>();

  const response = await fetch(`${apiUrl}/bankAccounts`, {
    method: "GET",
    headers: requestHeaders({ token: token })
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export async function getTransactions(token: string) {
  const result = new Result<ITransaction[], any>();

  const response = await fetch(`${apiUrl}/transactions`, {
    method: "GET",
    headers: requestHeaders({ token: token })
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

interface IUpdateTransactionRequest {
  category?: string;
  ignore?: boolean;
}
export async function updateTransaction(
  token: string,
  id: string,
  request: IUpdateTransactionRequest
) {
  const result = new Result<ITransaction, any>();

  const response = await fetch(`${apiUrl}/transactions/${id}`, {
    method: "PATCH",
    headers: requestHeaders({ token: token }),
    body: JSON.stringify(request)
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}

export async function getCategories(token: string) {
  const result = new Result<ICategory[], any>();

  const response = await fetch(`${apiUrl}/categories`, {
    method: "GET",
    headers: requestHeaders({ token: token })
  });

  if (response.status === 200) {
    return result.succeed(await response.json());
  } else if (response.status === 400) {
    return result.fail(await response.json());
  } else {
    return result.fail(null);
  }
}
