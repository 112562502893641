export const apiUrl = process.env.REACT_APP_API_URL;

interface IHeadersOptions {
  token?: string;
  contentType: string;
  accept: string;
}

const defaultHeaderOptions: IHeadersOptions = {
  contentType: "application/json",
  accept: "application/json"
};

export function requestHeaders(
  optionOverrides: Partial<IHeadersOptions> = {}
): Headers {
  const options = Object.assign({}, defaultHeaderOptions, optionOverrides);

  const headers = new Headers();
  headers.append("Content-Type", options.contentType);
  headers.append("Accept", options.accept);

  if (options.token) {
    headers.append("Authorization", `Bearer ${options.token}`);
  }

  return headers;
}
