import React, { useRef } from "react";
import { Formik, FormikHelpers, Field } from "formik";
import styled from "@emotion/styled";
import CategoryInput, { CommitAction } from "../molecules/CategoryInput";
import AmountInput from "../molecules/AmountInput";
import IconButton from "../atoms/IconButton";
import { faCheckCircle, faTrash } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  padding: 1rem 0;
`;

const Form = styled.form`
  display: flex;
`;

const Title = styled.div`
  flex-grow: 1;
`;

const Carryover = styled.label`
  margin-right: 1rem;
`;

const Bar = styled.div`
  height: 0.2rem;
  margin-top: -0.2rem;
  background-color: #c0c0c0;
  width: 100%;
`;

const StyledAmountInput = styled(AmountInput)`
  width: 7rem;
  min-width: 7rem;
`;

interface IFormValues {
  category: string;
  amount: string;
  carryover: boolean;
}

interface IProps {
  initialValues: IFormValues;
  onSubmit: (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => void;
  onDelete?: () => void;
}

const BudgetLineForm = ({ initialValues, onSubmit, onDelete }: IProps) => {
  const amountRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>
              <CategoryInput
                borderBottomWidth="0.2rem"
                borderBottomColor="#c0c0c0"
                paddingBottom="0.3rem"
                value={values.category}
                onCommit={(value, action) => {
                  setFieldValue("category", value);

                  if (
                    action === CommitAction.Click ||
                    action === CommitAction.EnterKey
                  )
                    amountRef.current?.focus();
                }}
              />
            </Title>
            <Carryover>
              <Field name="carryover" type="checkbox" />
              Carryover
            </Carryover>
            <StyledAmountInput
              name="amount"
              borderBottomWidth="0.2rem"
              borderBottomColor="#c0c0c0"
              paddingBottom="0.3rem"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.amount}
              ref={amountRef}
            />
            {onDelete && (
              <IconButton
                icon={faTrash}
                onClick={onDelete}
                disabled={isSubmitting}
              />
            )}
            <IconButton
              icon={faCheckCircle}
              type="submit"
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
      <Bar />
    </Container>
  );
};

export default BudgetLineForm;
